import { render, staticRenderFns } from "./index.vue?vue&type=template&id=037096be"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JbLogin: require('/buddy/advantis-frontend/node_modules/jbcomponents/components/Jb/Login.vue').default})
